body {
	font-family: Noto Sans, Helvetica, sans-serif;
	text-align: center;
}
.blue-banner-error {
    background-color: #044d66;
    color: #fff;
    padding: 50px 0;
}
.error-image {
    padding: 30px 0 50px;
}
.error-image img {
    max-width: 70%;
}
button {
    FONT-SIZE: 13px;
    padding: 10px 50px;
    letter-spacing: 1px;
    background: none;
    border: 2px solid white;
    border-radius: 70px;
    margin: 14px 20px;
}